body {
  /* font-family: freight-sans-pro, Arial, sans-serif; */
  font-family: 'Times New Roman', Times, serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

* {
  box-sizing: border-box;
}
