.root {
  position: relative;
  max-width: 700px;
  padding: 30px 0 50px;
  margin: 0 auto;
}

.list {
  display: grid;
  padding: 0;
  margin: 0;
  gap: 20px;
  list-style: none;
}

.clearButton {
  position: absolute;
  top: 40px;
  right: 15px;
  padding: 0;
  border: 0;
  background-color: inherit;
  cursor: pointer;
  font-size: 14px;
  letter-spacing: 1.5px;
  outline: 0;
  text-transform: lowercase;
  transition: opacity .5s ease;
}

.clearButton:hover,
.clearButton:focus-visible {
  opacity: .6;
}

.message {
  padding: 20px;
  margin: 0;
  color: var(--color-primary-dark-translucent);
  font-size: 16px;
  letter-spacing: 2px;
  line-height: 1.5;
  text-align: center;

  /* text-transform: uppercase; */
}

@media (max-width: 500px) {
  .root {
    padding: 20px 0;
  }

  .clearButton {
    top: 25px;
  }
}
