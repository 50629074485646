.button {
  padding: 12px 18px;
  cursor: pointer;
  outline: none;
}

.l.button {
  min-width: 185px;
  height: 72px;
}

.m.button {
  min-width: 105px;
  height: 39px;
}

.primary.button {
  border: 1px solid var(--color-primary-dark);
  background-color: transparent;
  font-size: 16px;
  letter-spacing: 4px;
  line-height: 1;
  text-transform: uppercase;
  transition: color .5s, background-color .5s ease;
}

.secondary {
  border: none;
  background-color: var(--color-secondary-light);
  border-radius: 10px;
  font-size: 20px;
  letter-spacing: 2px;
  line-height: .5;

  /* text-transform: lowercase; */
  transition: opacity .5s ease;
}

.primary.button:hover,
.primary.button:focus-visible {
  background-color: var(--color-primary-dark);
  color: var(--color-primary-light);
}

.primary.button:disabled {
  border: 1px solid var(--color-primary-dark-translucent);
  color: var(--color-primary-dark-translucent);
  cursor: not-allowed;
}

.primary.button:disabled:hover {
  background-color: transparent;
}

.secondary.button:hover,
.secondary.button:focus-visible {
  opacity: .5;
}
