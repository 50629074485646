.root {
  width: 68.75%;
  max-width: var(--page-content-max-width);
  flex-shrink: 0;
  margin: 0 auto;
}

@media (max-width: 500px) {
  .root {
    width: 100%;
  }
}
