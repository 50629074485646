.root {
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-primary-dark-translucent);
  opacity: 0;
  transition: visibility .3s, opacity .3s ease;
  visibility: hidden;
}

.opened {
  opacity: 1;
  visibility: visible;
}

.container {
  position: relative;
  width: 500px;
  box-sizing: border-box;
  padding: 60px 40px;
  background-color: var(--color-primary-light);
  border-radius: 5px;
  color: var(--color-primary-dark);
  font-family: freight-big-pro, Arial, sans-serif;
  font-size: 24px;
  letter-spacing: 2px;
  line-height: 1;
  text-align: center;
}

@media (max-width: 500px) {
  .container {
    width: calc(100% - 14px * 2);
    font-size: 18px;
  }
}
