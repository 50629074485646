.container {
  display: flex;
  justify-content: center;
}

.text {
  margin: 0;
  font-size: 14px;
  letter-spacing: 2px;
  line-height: 1.5;
}

.link {
  position: relative;
  color: inherit;
  outline: none;
  text-decoration: none;
}

.link:hover::after,
.link:focus-visible::after {
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 100%;
  height: 1px;
  animation: underline .5s;
  background-color: var(--color-primary-dark-translucent);
  content: "";
  transform-origin: 0;
}

@keyframes underline {
  0% {
    transform: scaleX(0);
  }

  100% {
    transform: scaleX(1);
  }
}

@media (max-width: 500px) {
  .text {
    font-size: 12px;
  }
}
