.container {
  display: flex;
  justify-content: center;
  padding: 0 0 20px;
  border-bottom: 1px solid var(--color-primary-dark);
}

.title {
  margin: 0;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: 60px;
  font-weight: 500;
  line-height: 1;

  /* text-transform: uppercase; */
}

@media (max-width: 1024px) {
  .title {
    font-size: 50px;
  }
}

@media (max-width: 500px) {
  .container {
    display: block;
  }

  .title {
    margin-left: 27px;
    font-size: 36px;
  }
}
