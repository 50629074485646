@font-face {
  font-family: freight-big-pro;
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/FreightBigProBook-Regular.woff2') format('woff2'),
    url('./fonts/FreightBigProBook-Regular.woff') format('woff');
}

@font-face {
  font-family: freight-sans-pro;
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/FreightSansProLight-Regular.woff2') format('woff2'),
    url('./fonts/FreightSansProLight-Regular.woff') format('woff');
}

@font-face {
  font-family: freight-sans-pro;
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/FreightSansProBook-Regular.woff2') format('woff2'),
    url('./fonts/FreightSansProBook-Regular.woff') format('woff');
}
