.root {
  width: 100%;
  margin: 0 0 20px;
  font-family: "Times New Roman", Times, serif;
  font-size: 30px;
  font-weight: 500;
  line-height: 1;

  /* text-transform: lowercase; */
}

@media (max-width: 500px) {
  .root {
    margin-bottom: 10px;
  }
}
